<template>
  <div>
    <base-sign-page
      title-text="数据库操作记录"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      :search-parms="searchParms"
      @clickSearch="loadData"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      v-loadingx="loading"
      :need-pagination="true">
    </base-sign-page>
    <slot></slot>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  dbOperationLogRequest
} from '@/api'

export default {
  components: {
    BaseSignPage
  },
  computed: {
    columnList () {
      return [{
        field: 'createTime',
        title: '操作时间',
      },
      {
        field: 'userName',
        title: '操作人'
      },
      {
        field: 'name',
        title: '操作类型'
      }]
    },
    appServiceList () {
      return this.$store.getters.appServiceList.filter(v => v.data.appType === 'mysql')
    },
    searchParms () {
      let data = [{
        type: 'select',
        placeholder: '服务',
        selectDatas: this.appServiceList,
        key: 'appServiceId',
        default: Number(this.$router.history.current.query.appServiceId || 0) || null
      }]
      return data
    }
  },
  created () {
    this.searchData.appServiceId = Number(this.$router.history.current.query.appServiceId || 0) || null
    this.$store.dispatch('loadAppServiceList')
    this.loadData()
  },
  methods: {
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      } else {
        this.$emit('tableAction', data)
      }
    },
    loadData (searchParm) {
      this.searchData = searchParm || this.searchData
      // if (!this.searchData.appServiceId) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '请选择服务器'
      //   })
      //   return
      // }
      this.loading = true
      dbOperationLogRequest.get(this.searchData).then((data) => {
        // data.forEach(v => v.isSuccessText = v.isSuccess ? '成功' : '失败')
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      serverList: [],
      formParms: null,
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      loading: false,
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
